import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

export type CookieConsentLevels = { [level: string]: boolean };

export type CookieConsentLevelsContext = {
  cookieConsentLevels: CookieConsentLevels | null;
  setCookieConsentLevels: Dispatch<SetStateAction<CookieConsentLevels | null>>;
};

export const CookieConsentContext = createContext<CookieConsentLevelsContext>({
  cookieConsentLevels: null,
  setCookieConsentLevels: () => {},
});

type CookieConsentContextProviderProps = {
  children?: React.ReactNode;
};

const CookieConsentContextProvider: React.FC<
  CookieConsentContextProviderProps
> = (props) => {
  const [cookieConsentLevels, setCookieConsentLevels] =
    useState<CookieConsentLevelsContext["cookieConsentLevels"]>(null);

  const contextProviderValue: CookieConsentLevelsContext = {
    cookieConsentLevels,
    setCookieConsentLevels,
  };

  return (
    <CookieConsentContext.Provider value={contextProviderValue}>
      {props.children}
    </CookieConsentContext.Provider>
  );
};

export default CookieConsentContextProvider;
